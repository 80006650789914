














































@import "@/assets/css/project-variables";
.deviation-cell-high {
  background-color: #ffe3e3 !important;
}
.deviation-cell-normal {
  background-color: #fafafa !important;
}
.deviation-cell-low {
  background-color: #e8ffe0 !important;
}
